<div class="navigation-title">
    <div class="back-button" (click)="backButton()">
        <fa-icon [icon]="faArrowCircleLeft" [size]="'xl'"></fa-icon>
    </div>
    <div class="title">{{currentTitle}}</div>
</div>
<div class="container-fluid deposit-mobile-component px-0 pt-3 pb-5">
    <div class="main-deposit-mobile">
        @if(loader) {
        <div class="spinner-container">
            <div class="spinner-border" style="margin: 0 auto;color: var(--accent-main-text-color);" role="status"></div>
        </div>
        }
        @if(!showFormPaymentAuto && !showFormPaymentManual &&!loader){
            @if(!nextStepOption){
            <div class="row align-items-center main-deposit py-5 mb-5">
                <div class="title-page-small h5">
                    Pilih Opsi Pembayaran
                </div>
                <div class="option-payment">
                    <div class="auto-button-big mt-4" [ngClass]="currentOptionPayment === 'auto' ? 'active-button':'' "
                        (click)="selectOptionPayment('auto')">
                        <div class="img-button">
                            <img src="../../../template/green/assets/images/auto.png">
                            <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                        </div>
                        <div class="text-container">
                            <span class="text-main">Auto Deposit</span>
                            <span class="text-sub">Deposit dengan payment gateway</span>
                        </div>
                    </div>
                    <div class="manual-button-big mt-4" [ngClass]="currentOptionPayment === 'manual' ? 'active-button':''"
                        (click)="selectOptionPayment('manual')">
                        <div class="img-button">
                            <img src="../../../template/green/assets/images/manual.png">
                            <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                        </div>
                        <div class="text-container">
                            <span class="text-main">Manual Deposit</span>
                            <span class="text-sub">Deposit transfer bank dan lainnya melalui admin</span>
                        </div>
                    </div>
            
                </div>
                <button type="button" class="fixed-bottom text-uppercase border-0 outline-0 text-white w-100 btn-sign-mobile" (click)="nextButtonOption()">PILIH</button>
            
            </div>
            } @else {
            <div class="row align-items-center main-deposit py-5 mb-5">
                <div class="title-page-small h5">
                    Pilih Metode Pembayaran
                </div>
                <div class="option-payment">
                    <div class="auto-button-big mt-4" [ngClass]="currentMethodPayment === 'vacc' ? 'active-button':'' "
                        (click)="selectMethodPayment('vacc')">
                        <div class="img-button">
                            <img src="../../../template/green/assets/images/vacc.png">
                        </div>
                        <div class="text-container">
                            <span class="text-main">Virtual Account</span>
                            <span class="text-sub">Transfer dengan cepat ke akun bank virtual pilihan anda</span>
                        </div>
                    </div>
                    <div class="manual-button-big mt-4" [ngClass]="currentMethodPayment === 'qris' ? 'active-button':''"
                        (click)="selectMethodPayment('qris')">
                        <div class="img-button">
                            <img src="../../../template/green/assets/images/qris.png">
                        </div>
                        <div class="text-container">
                            <span class="text-main">QRIS</span>
                            <span class="text-sub">Pembayaran cepat dan mudah dengan scan barcode QRIS</span>
                        </div>
                    </div>
            
                </div>
                <button type="button" class="fixed-bottom text-uppercase border-0 outline-0 text-white w-100 btn-sign-mobile" (click)="nextButtonMethod()">PILIH</button>
            
            </div>
            }
        }@else{
            @if(!hideSwitchMenu && depositConfig['status']){
                @if(!hideSwitchMenu && depositConfig['status']){
                    <div class="container-menu-option">
                        <div class="small-option-payment">
                            <div class="auto-button-small mb-4" [ngClass]="currentOptionPayment === 'auto' ? 'active-button':'' "
                                (click)="selectOptionPayment('auto'); showFormPaymentAuto = true">
                                <div class="text-container">
                                    <span class="text-main">Bayar Otomatis</span>
                                </div>
                            </div>
                            <div class="manual-button-small mb-4" [ngClass]="currentOptionPayment === 'manual' ? 'active-button':''"
                                (click)="selectOptionPayment('manual'); showFormPaymentManual = true">
                                <div class="text-container">
                                    <span class="text-main">Bayar Manual</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            }
            <!--auto payment-->
            @if(showFormPaymentAuto && currentOptionPayment ==='auto'){}
            <!--end auto payment-->

            @if(showFormPaymentManual && currentOptionPayment === "manual" && !loader){
                <app-deposit-manual-mobile></app-deposit-manual-mobile>
            }
        }
    </div>
</div>