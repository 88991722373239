import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormControl, FormsModule, NgForm, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@componentpath/services/auth.service';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faBank, faCaretDown, faCircleCheck, faEye, faEyeSlash, faMagnifyingGlass, faPhone, faUser, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';
import { FilterMobileComponent } from '@template/green/mobile/filter-mobile/filter-mobile.component';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { RouterExtService } from '@componentpath/services/scripts.service';

@Component({
  selector: 'app-register-mobile',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [FontAwesomeModule, FormsModule, ReactiveFormsModule, NgbDropdownModule, CommonModule, FilterMobileComponent],
  templateUrl: './register-mobile.component.html',
  styleUrl: './register-mobile.component.scss'
})
export class RegisterMobileComponent {

  faUser: IconProp = faUser;
  faEye: IconProp = faEye;
  faEyeSlash: IconProp = faEyeSlash;
  faPhone: IconProp = faPhone;
  faBank: IconProp = faBank;
  faUserGroup: IconProp = faUserGroup;
  faCaretDown: IconProp = faCaretDown;
  faArrowLeft: IconProp = faArrowLeft;
  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  faCircleCheck: IconProp = faCircleCheck;

  show = true;
  usernameRegister = new FormControl('', [
    Validators.required,
    this.clearWhiteSpace(),
    this.existUsernameValidator(),
    Validators.minLength(1)
  ]);
  emailRegister = new FormControl('', [
    Validators.email,
  ]);
  phoneRegister = new FormControl('', [
    Validators.required,
    Validators.minLength(7),
    // Validators.pattern('^[0-9]*$')
  ]);
  accNumberRegister = new FormControl('', [
    Validators.required,
    Validators.maxLength(20)
  ]);
  accNameRegister = new FormControl('', [
    Validators.required,
  ]);
  passwordRegister = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    // Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$')
  ]);
  passwordConfirmRegister = new FormControl('', [
    this.checkConfirmPasswordValidator()
  ]);
  pinRegister = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
    Validators.pattern('^[0-9]*$')
  ]);
  pinConfirmRegister = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
    this.checkConfirmPinValidator()
  ]);
  referralRegister = new FormControl('', []);

  inputSearchCreate: string = '';

  isReferralDisabled = false;

  isUsernameAvailable = true;
  isPasswordVisible = false;
  isConfirmPasswordVisible = false;
  isPinVisible = false;
  isConfirmPinVisible = false;

  options: any[] = [];
  isDropdownVisible: boolean = false;
  selectedOption: string = '';
  dropdownOpened: boolean = false;
  showBankNotification: boolean = false;
  isExistUsername: boolean = false;
  checkedUsername: boolean = false;
  disableButtonRegister: boolean = false;
  selectedBankCompany: Record<string, any>={};
  searchedCreateOptions: Array<any> = [];
  currentSelectedCreateBank: Record<string, any> = {};

  websiteJSONData: any = this.data.websiteJSONData;

  prevURL: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private snackBar: MatSnackBar,
    private cookie: SsrCookieService,
    private routerExtService: RouterExtService,
    private bottomSheet: MatBottomSheet
  ){
    if (!this.websiteJSONData.configuration.pin) {
      this.pinRegister.setValue('111111');
    }
  }

  backButton() {
      if (isPlatformBrowser(this.platformId)) {
        this.prevURL = this.routerExtService.getPreviousUrl();
        if (this.prevURL === this.router.url) {
          this.router.navigate(['/']);
        } else {
          this.router.navigateByUrl(this.prevURL);
        }
    }
  }

  selectedCreateBank(e: any) {
    this.currentSelectedCreateBank = e;
  }

  clearWhiteSpace(): ValidatorFn {
    return (): ValidationErrors | null => {
      if (this.usernameRegister) {
        const isWhitespace = (this.usernameRegister.value || '').match(/\s/g);
        return isWhitespace ? { 'whitespace': true } : null;
      } else {
        return null;
      }
    };
  }

  // password & pin input
  checkConfirmPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null =>
      control.value === this.passwordRegister.value
        ? null : { confirmedPassword: true };
  }

  checkConfirmPinValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null =>
      control.value === this.pinRegister.value
        ? null : { confirmedPin: true };
  }
  // end password & pin input


  // username input

  existUsernameValidator(): ValidatorFn {
    return (): ValidationErrors | null => {
      if (this.isExistUsername) {
        return { existUserName: true };
      } else {
        return null;
      }
    };
  }

  checkAllInput(): boolean {
    console.log('aaaaaaaa');
    if (this.checkedUsername && this.usernameRegister.valid && this.emailRegister.valid && this.phoneRegister.valid && this.passwordRegister.valid && this.passwordConfirmRegister.valid && this.pinRegister.valid && this.pinConfirmRegister.valid && this.accNumberRegister.valid && this.accNameRegister.valid) {
      return true
    } else {
      this.usernameRegister.markAsDirty();
      this.emailRegister.markAsDirty();
      this.phoneRegister.markAsDirty();
      this.passwordRegister.markAsDirty();
      this.passwordConfirmRegister.markAsDirty();
      this.pinRegister.markAsDirty();
      this.pinConfirmRegister.markAsDirty();
      this.accNumberRegister.markAsDirty();
      this.accNameRegister.markAsDirty();
      return false
    }
  }

  usernameChange() {
    if (this.checkedUsername) {
      this.checkedUsername = false;
    }
    this.isExistUsername = false;
    this.usernameRegister.updateValueAndValidity();
  }

  accNameRegisterOnInput() {
    this.accNameRegister.setValue(this.accNameRegister.value?.toUpperCase() as string);
    // console.log(this.accNameRegister.value?.toUpperCase() as string)
  }

  userNameRegisterOnInput() {
    this.usernameRegister.setValue(this.usernameRegister.value?.toUpperCase() as string);
    // console.log(this.usernameRegister.value?.toUpperCase() as string)
  }

  referralRegisterOnInput() {
    this.referralRegister.setValue(this.referralRegister.value?.toUpperCase() as string);
    // console.log(this.usernameRegister.value?.toUpperCase() as string)
  }

  checkUsername() {
    if (this.usernameRegister.value !== (undefined || '')) {
      this.request.checkUsername(this.usernameRegister.value?.toUpperCase() as string).subscribe({
        next: (v) => {
          const value = v as Record<string, unknown>;
          // console.log(value);
          if (value['code'] === 200) {
            this.checkedUsername = true;
            this.isExistUsername = false;
            // console.log(this.usernameRegister.valid)
          }

        },
        error: (e) => {
          const errorMessage =
            e.error.messages[0].message || 'Registration failed';
          console.log(e['error']);
          if (e['error']['data']['validate'] === false) {
            this.checkedUsername = false;
            this.isExistUsername = true;
            this.disableButtonRegister = false;
          }
          if (e.error['messages']) {
            // this.toastService.showToasts(e.error['messages'] as Record<string, string | number | boolean>[]);
          }
          else {
            // this.toastService.showToast({ message: 'Error communication with API - Please check your internet connection', status: 'error' });
          }
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: errorMessage, type: 'mobile-error' },
            duration: 10000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: "notification-error"
          });

          this.usernameRegister.markAsDirty();
          this.usernameRegister.updateValueAndValidity();
        },
        complete: () => {
          this.usernameRegister.markAsDirty();
          this.usernameRegister.updateValueAndValidity();
          this.register();
        }
      })
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'Mohon Periksa Kembali Input Yang Anda Isi', type: 'mobile-error' },
        duration: 10000,
        horizontalPosition: "center",
        verticalPosition: "top",
        panelClass: "notification-error"
      });
      this.disableButtonRegister = false;
    }
  }

  onSearchcreateValue(e: Event) {
    const input = this.inputSearchCreate.toLowerCase();
    let typeLists: Array<any> = [];

    typeLists = this.options;

    this.searchedCreateOptions = typeLists.filter((option) =>
      (option['name'].toLowerCase().includes(input))
    );

    if (!input) {
      this.searchedCreateOptions = [...typeLists];
    }
  }

  openFilterSelectCreateBank(currentFilter: any) {
    if (currentFilter == undefined) {
      currentFilter = this.currentSelectedCreateBank;
    }
    // console.log(currentFilter)
    let listArray = this.searchedCreateOptions
    // console.log(listArray);
    let dataFilter = {
      type: 'simple-search',
      data: {
        currentFilter: currentFilter,
        listArray: listArray
      }
    }
    this.bottomSheet.open(FilterMobileComponent, {
      data: dataFilter
    }).afterDismissed().subscribe((v) => {
      // console.log(v)
      if (v !== undefined) {
        this.selectedCreateBank(v);
      }
    })
  }

  // toggleDropdown(event: MouseEvent): void {
  //   this.isDropdownVisible = !this.isDropdownVisible;
  //   event.stopPropagation();
  // }

  // handleOutsideClick(event: MouseEvent): void {
  //   if (this.elementRef.nativeElement.contains(event.target)) {
  //     if (this.isDropdownVisible) {
  //       this.isDropdownVisible = false;
  //       this.showBankNotification = !this.selectedOption;
  //     }
  //   }
  // }

  // onSearchDropdownValue(event: Event): void {
  //   const input = (event.target as HTMLInputElement).value.toLowerCase();
  //   this.searchedOptions = this.options.filter((option) =>
  //     option.name.toLowerCase().includes(input)
  //   );
  //   if (!input) {
  //     this.searchedOptions = this.options;
  //   }

  //   this.isDropdownVisible = this.searchedOptions.length > 0;
  // }

  // onSelectDropdownValue(option: any): void {
  //   this.selectedOption = option;
  //   this.searchedOptions = [];
  //   this.isDropdownVisible = false;

  //   this.showBankNotification = false;
  // }

  // onDocumentClick(event: MouseEvent): void {
  //   const isClickInside = this.elementRef.nativeElement.contains(event.target);
  //   if (!isClickInside && this.isDropdownVisible) {
  //     this.isDropdownVisible = false;
  //   }
  // }

  // toUppercase(event: string, field: string): void {
  //   if (field === 'username') {
  //     this.username = event.toUpperCase();
  //   } else if (field === 'referral') {
  //     this.referral = event.toUpperCase();
  //   }
  // }
  // checkUsernameAvailability(): void {
  //   if (this.username.trim() === '') {
  //     return;
  //   }

  //   this.request.checkUsername(this.username).subscribe({
  //     next: (response) => {
  //       console.log(response.data.validate, 'response avail');
  //       this.isUsernameAvailable = true;
  //     },
  //     error: (error) => {
  //       console.error('Error checking username:', error);
  //       this.isUsernameAvailable = false;
  //     },
  //   });
  // }
  // toggleVisibility(field: string): void {
  //   switch (field) {
  //     case 'password':
  //       this.isPasswordVisible = !this.isPasswordVisible;
  //       break;
  //     case 'confirmPassword':
  //       this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  //       break;
  //     case 'pin':
  //       this.isPinVisible = !this.isPinVisible;
  //       break;
  //     case 'confirmPin':
  //       this.isConfirmPinVisible = !this.isConfirmPinVisible;
  //       break;
  //     default:
  //       break;
  //   }
  // }
  // public checkPinMatch(): boolean {
  //   return this.pin === this.confirmPin;
  // }

  // public checkPasswordMatch(): boolean {
  //   return this.password === this.confirmPassword;
  // }

  registerForm(){
    this.disableButtonRegister = true;
    this.checkUsername();
  }

  public register(): void {
    if (this.websiteJSONData['configuration']['pin']=== false) {
      this.pinRegister.setValue('111111');
      this.pinConfirmRegister.setValue('111111');
    }
    if (isPlatformBrowser(this.platformId)) {
      if (this.checkAllInput()) {
        this.request.registerUser(
          '',
          this.usernameRegister.value?.toUpperCase() as string,
          this.passwordRegister.value as string,
          this.pinRegister.value as string,
          this.referralRegister.value?.toUpperCase() as string,
          '',
          this.phoneRegister.value as string,
          'ID',
          this.currentSelectedCreateBank['_id'] as string,
          this.accNameRegister.value?.toUpperCase() as string,
          this.accNumberRegister.value as string,
          ).subscribe({
            next: (response) => {
              if (response.code === 200) {
                response.data.hide_pin = true;
                response.data.group = {
                  "_id": "63d94aa59b650dff710cce53",
                  "user": "634ff0bde83e948a230b9402",
                  "name": "BRONZE",
                  "keyword": "BRONZE",
                  "rank": 1,
                  "updated_at": "2024-05-17 08:00:29",
                  "created_at": "2023-01-31 17:06:45",
                  "auto_upgrade": true,
                  "private": false,
                  "badge_image": "",
                  "card_image": "",
                  "rule": [],
                  "status": true
                }
                this.auth.setLogin(response.data, {});
                this.cookie.delete('ref');
              } else {
                console.error('Registration failed:', response.message);
                const message = response.message || 'Registration failed';
                // this.toastService.show({
                //   template: this.errorToastTpl,
                //   classname: 'bg-danger text-light',
                //   context: { message },
                //   delay: 8000,
                // });
              }
            },
            error: (error) => {
              const errorMessage =
                error.error.messages[0].message || 'Registration failed';
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: { message: errorMessage, type: 'mobile-error' },
                  duration: 10000,
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  panelClass: "notification-error"
                });
                // this.toastService.show({
                //   template: this.errorToastTpl,
                //   classname: 'bg-danger text-light',
                //   context: { message: errorMessage },
                //   delay: 8000,
                // });
                this.disableButtonRegister = false;
            },
            complete: () => {
              this.router.navigate(['/']);
              // console.log(this.auth.sessionValue);
            },
          });
      } else {
        this.disableButtonRegister = false;
        this.emailRegister.updateValueAndValidity();
        this.phoneRegister.updateValueAndValidity();
        this.passwordRegister.updateValueAndValidity();
        this.passwordConfirmRegister.updateValueAndValidity();
        this.pinRegister.updateValueAndValidity();
        this.pinConfirmRegister.updateValueAndValidity();

        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: 'Mohon Periksa Kembali Input Yang Anda Isi', type: 'mobile-error' },
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: "notification-error"
        });
      }

    }
  }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
      let dataBank = this.data.bankRegisterJSONData['records'] as any[];
      this.options = dataBank.filter((option) =>
        ((option['type'] === 1 || option['type'] === 2) && option['code'] !== 'oth' && option['status'])
      );
      console.log(this.options);
      this.searchedCreateOptions = this.options;
      this.currentSelectedCreateBank = this.options[0];
      this.activatedRoute.queryParams.subscribe((params) => {
        // console.log('aaaa')
        if(params['ref'] != undefined && params['ref'] != '') {
          this.referralRegister.setValue(params['ref'].toUpperCase());
          this.referralRegister.disable();
        } else {
          let dataRef = this.cookie.get('ref');
          // console.log(dataRef)
          if(dataRef){
            this.referralRegister.setValue(dataRef.toUpperCase());
            this.referralRegister.disable();
          }
          // let dataRef = this.cookies.get('v3_ref');
          // if (dataRef) {
            //   this.referralRegister.setValue(dataRef.toUpperCase());
            //   this.referralRegister.disable();
            // }
          }
      });
    }
  }

  ngOnDestroy(): void {
    // this.toastService.clear();
  }
}
